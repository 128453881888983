const cognito = {
  REGION: 'eu-central-1',
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  TOKEN: process.env.REACT_APP_COGNITO_TOKEN
}

export const adminUsers = ['office@getnano.io', 'hello.getnano@stylink.com', 'ugc@stylink.com']

export default {
  s3: {
    REGION: 'eu-central-1',
    BUCKET: 'com.freebeeapp'
  },
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://mnkwl5qmzd.execute-api.eu-central-1.amazonaws.com/dev'
  },
  cognito
}


// src/config/videoUrls.ts

interface VideoUrlConfig {
  default: {
    gb: string
    default: string
  }
  premium: {
    gb: string
    default: string
  }
  featureSubtitles: {
    gb: string
    default: string
  },
  featureLogo: {
    gb: string
    default: string
  }
}

export const VIDEO_URLS: VideoUrlConfig = {
  default: {
    gb: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/exampl_video.mp4',
    default: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/ad_video.mp4'
  },
  premium: {
    gb: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/exampl_video.mp4',
    default: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/premium_video.mp4'
  },
  featureSubtitles: {
    gb: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/Color_Wow_Untertitel_EN.mp4',
    default: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/package_small_new.mp4'
  },
  featureLogo: {
    gb: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/Bodylab_Untertitel_Logo_EN.mp4',
    default: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/package_medium_new.mp4'
  }
}

export const inspitrationVideos = (strings: any) => ({
  dach: [
    {
      title1: strings.inspo_classic_title_1,
      title2: strings.inspo_classic_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_classic.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_classic_thumb.png',
      bulletPoints: [strings.inspo_classic_desc_1, strings.inspo_classic_desc_2, strings.inspo_classic_desc_3]
    },
    {
      title1: strings.inspo_unboxing_title_1,
      title2: strings.inspo_unboxing_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_unboxing.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_unboxing_thumb.png',
      bulletPoints: [strings.inspo_unboxing_desc_1, strings.inspo_unboxing_desc_2, strings.inspo_unboxing_desc_3]
    },
    {
      title1: strings.inspo_digital_title_1,
      title2: strings.inspo_digital_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_digital.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_digital_thumb.png',
      bulletPoints: [strings.inspo_digital_desc_1, strings.inspo_digital_desc_2, strings.inspo_digital_desc_3]
    },
    {
      title1: strings.inspo_industry_title_1,
      title2: strings.inspo_industry_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_industry.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_industry_thumb.png',
      bulletPoints: [strings.inspo_industry_desc_1, strings.inspo_industry_desc_2, strings.inspo_industry_desc_3]
    }
  ],
  gb: [
    {
      title1: strings.inspo_classic_title_1,
      title2: strings.inspo_classic_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/ugc/Beauty_Jodie.mov',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/ugc/Jodie_UK_Inspiration_Thumbnail.png',
      bulletPoints: [strings.inspo_classic_desc_1, strings.inspo_classic_desc_2, strings.inspo_classic_desc_3]
    },
    {
      title1: strings.inspo_unboxing_title_1,
      title2: strings.inspo_unboxing_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/ugc/UGC+Sockenvideo_V1_9zu16.mov',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/ugc/Socken_UK_Inspiration_Thumbnail.png',
      bulletPoints: [strings.inspo_unboxing_desc_1, strings.inspo_unboxing_desc_2, strings.inspo_unboxing_desc_3]
    }
  ]
})

export const getVideoUrl = (type: 'default' | 'premium' | 'featureSubtitles' | 'featureLogo', rootStore: any) => {
  const brandCountry = rootStore.brandStore.selectedBrand?.brandCountry?.toLowerCase()

  // Check if there's a specific URL for the brand country, otherwise use default
  return brandCountry === 'gb' ? VIDEO_URLS[type].gb : VIDEO_URLS[type].default
}