import CloseIcon from '@mui/icons-material/Close'
import StarIcon from '@mui/icons-material/Star'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useStrings } from '../../../assets/localization/strings'
import rootStore from '../../../stores/rootStore'
import { theme } from '../../../theme'
import { Player } from '../../common/Player'

type Props = Record<'influencerId' | 'campaignId' | 'content', string | undefined> & { open: boolean; close(): void }
export const RatingDialog = ({ open, close, influencerId, campaignId, content }: Props) => {
  const strings = useStrings()
  const [rating, setRating] = useState<number>()
  const [feedback, setFeedback] = useState('')
  const [loading, setLoading] = useState(false)
  const vertical = useMediaQuery(theme.breakpoints.down('sm'))

  const sendFeedback = async () => {
    if (!rating || !influencerId || !campaignId) return
    setLoading(true)
    try {
      await rootStore.campaignStore.setContentFeedback({ influencerId, campaignId, ratingFeedback: feedback, rating })
      setRating(undefined)
      setFeedback('')
      close()
    } finally {
      setLoading(false)
    }
  }

  const enabled = rating && !!feedback

  return (
    <Dialog open={open} maxWidth='lg'>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {strings.campaignOverviewContentGiveRatingTitle}
        <IconButton onClick={close} sx={{ ml: 2 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{strings.campaignOverviewContentGiveRatingBody}</Typography>
        <Stack direction={vertical ? 'column' : 'row'} sx={{ pl: vertical ? 3 : 5, pr: vertical ? 3 : 5, pt: 3, pb: 4 }}>
          <Stack spacing={4} alignItems={vertical ? 'center' : 'start'}>
            <Player src={content} />
          </Stack>
          <Stack alignItems='center' justifyContent='center' spacing={2} m={2} pl={vertical ? 0 : 10} mt={vertical ? 3 : 0}>
            <Stack direction='row'>
              {[...Array(5)].map((_, i) => (
                <IconButton key={i} onClick={() => setRating(i + 1)} className='rating'>
                  <StarIcon color={rating && i + 1 <= rating ? 'primary' : undefined} />
                </IconButton>
              ))}
            </Stack>
            <TextField
              id='rating_feedback'
              fullWidth
              multiline
              value={feedback}
              placeholder={strings.campaignOverviewContentGiveRatingPholder}
              onChange={e => setFeedback(e.target.value)}
            />
            <LoadingButton id='send_rating' loading={loading} onClick={sendFeedback} variant='contained' disabled={!enabled}>
              {strings.campaignOverviewContentGiveRatingButton}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
