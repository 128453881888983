import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Grid, Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { boolean, mixed, object, string } from 'yup'
import { analytics, TagManager } from '../..'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import { theme } from '../../theme'
import { AuthPage } from '../auth/Auth'
import ReactHookFormCheckbox from '../common/form/ReactHookFormCheckbox'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import { emailPasswordSchema } from './Login'
import ReactHookFormSelect from '../common/form/ReactHookFormSelect'
import { Country } from '../../models/GeneralTypes'

type ISignupForm = { firstname: string; surname: string; email: string; password: string; conditionsCheck: boolean; referralCode: string | null; country: Country }

type UTMData = {
  utm_source: string | null
  utm_medium: string | null
  utm_campaign: string | null
  utm_content: string | null
}

const SignUp = ({ pageSwitch }: { pageSwitch(page: AuthPage): void }) => {
  const strings = useStrings()
  const schema = object().shape({
    firstname: string().min(2, strings.login_register_yup_firstname1),
    surname: string().min(2, strings.login_register_yup_surname1),
    ...emailPasswordSchema(strings),
    conditionsCheck: boolean().required(strings.login_register_yup_confirmation),
    country: mixed().oneOf(Object.values(Country)).required(),
    referralCode: string().nullable()
  })
  const [loading, setLoading] = useState(false)
  const methods = useForm<ISignupForm>({ resolver: yupResolver(schema), defaultValues: { country: Country.germany, conditionsCheck: false } })
  const { setError, watch } = methods

  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const referralCode = searchParams.get('referral_code')

    if (referralCode) {
      methods.setValue('referralCode', referralCode)
    }
  }, [])

  const countryOptions = [
    { value: 'de', label: strings.brand_creation_country_germany as string },
    { value: 'at', label: strings.brand_creation_country_austria as string },
    { value: 'ch', label: strings.brand_creation_country_switzerland as string },
    { value: 'gb', label: strings.brand_creation_country_england as string }
  ]

  const getUTMData = (): UTMData => {
    const storedUtmData = localStorage.getItem('utmData')
    if (storedUtmData) {
      const parsedData = JSON.parse(storedUtmData)
      return {
        utm_source: parsedData.source,
        utm_medium: parsedData.medium,
        utm_campaign: parsedData.campaign,
        utm_content: parsedData.content
      }
    }

    const searchParams = new URLSearchParams(location.search)
    const utmSource = searchParams.get('utm_source')
    const utmMedium = searchParams.get('utm_medium')
    const utmCampaign = searchParams.get('utm_campaign')
    const utmContent = searchParams.get('utm_content')

    if (utmSource || utmMedium || utmCampaign || utmContent)
      return {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent
      }

    return {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null
    }
  }

  const formSubmitHandler: SubmitHandler<ISignupForm> = async (data: ISignupForm) => {
    const { firstname, surname, email, password, referralCode, country } = data
    setLoading(true)
    const utmData = getUTMData()
    if (referralCode) {
      try {
        const res = await rootStore.authStore.checkReferralCode({ referralCode })
        if (!res.referralCode) {
          setError('referralCode', { type: 'custom', message: strings.login_error_referral_code })
          setLoading(false)
          return
        }
      } catch (e) {
        setError('referralCode', { type: 'custom', message: strings.login_error_referral_code })
        setLoading(false)
        return
      }
    }

    try {
      const signUpResult = await rootStore.authStore.register(
        email.trim(),
        password,
        firstname,
        surname,
        utmData.utm_source || undefined,
        utmData.utm_medium || undefined,
        utmData.utm_campaign || undefined,
        utmData.utm_content || undefined,
        referralCode || undefined,
        country || undefined
      )

      const tagManagerArgs = {
        dataLayer: {
          userId: signUpResult.userSub,
          event: 'signUp',
          ...utmData
        }
      }
      TagManager.dataLayer(tagManagerArgs)

      await rootStore.authStore.login(email, password)
      analytics.logEvent('signUp', utmData)
      localStorage.removeItem('utmData')

      pageSwitch(AuthPage.onboarding)
    } catch (e) {
      console.error(e)
      //@ts-ignore
      if (e.code === 'UsernameExistsException') {
        setError('email', { type: 'custom', message: strings.login_error_email_exists })
      } else {
        setError('email', { type: 'custom', message: strings.login_error })
      }
    } finally {
      setLoading(false)
    }
  }

  const disabled = watch('email')?.length < 1 || watch('password')?.length < 1 || watch('firstname')?.length < 1 || watch('surname')?.length < 1 || !watch('conditionsCheck')

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Grid container justifyContent='center' sx={{ [theme.breakpoints.only('xs')]: { pl: 2, pr: 2 } }}>
          <Grid container flexDirection='column' spacing={2} item sm={7}>
            <Grid item>
              <ReactHookFormTextField label={strings.login_register_firstname_label + '*'} name='firstname' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_register_surname_label + '*'} name='surname' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_email_label + '*'} name='email' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_password_label + '*'} name='password' type='password' />
            </Grid>
            <Grid item>
              <ReactHookFormSelect name='country' label={strings.brand_creation_country_label + '*'} options={countryOptions} />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_register_referral_code} name='referralCode' />
            </Grid>

            <Grid item>
              <ReactHookFormCheckbox
                name='conditionsCheck'
                label={
                  <>
                    {strings.login_register_confirmation_part1}{' '}
                    <Link href={strings.login_agb_link} target='_blank'>
                      {strings.login_register_confirmation_part2}
                    </Link>{' '}
                    {strings.login_register_confirmation_part3}{' '}
                    <Link href={strings.login_privacy_link} target='_blank'>
                      {strings.login_register_confirmation_part4}
                    </Link>{' '}
                    {strings.login_register_confirmation_part5}
                  </>
                }
              />
            </Grid>
            <Grid item textAlign='center'>
              <LoadingButton variant='contained' loading={loading} type='submit' disabled={disabled} color='tertiary' id='REGISTER'>
                {strings.login_register_submit_button}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default SignUp
