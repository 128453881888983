
  /* eslint @typescript-eslint/no-unused-vars: 0 */
type P = string | number
type S = string
export default {
agency_link_box_desc: `Lass deine Kampagne mit unseren UGC-Creatives von unserer Partneragentur erstellen.`,
agency_link_box_header: `Agentur-Service`,
agency_link_box_linkbox: `Jetzt starten`,
agency_link_box_linkbox_url: `https://ugc.by.stylink.com/partneragentur`,
back: `Zurück`,
brand_creation_contact_email: `E-Mail Adresse*`,
brand_creation_contact_firstname: `Vorname*`,
brand_creation_contact_phone: `Telefonnummer*`,
brand_creation_contact_surname: `Nachname*`,
brand_creation_contact_title: `Ansprechperson`,
brand_creation_country_austria: `Österreich`,
brand_creation_country_england: `Vereinigtes Königreich`,
brand_creation_country_germany: `Deutschland`,
brand_creation_country_label: `Land`,
brand_creation_country_switzerland: `Schweiz`,
brand_creation_create_agency: `AGENTUR ERSTELLEN`,
brand_creation_create_brand: `BRAND ERSTELLEN`,
brand_creation_error: `Oh nein, du hast etwas vergessen!`,
brand_creation_info_agency_logo: `Logo der Agentur`,
brand_creation_info_agency_name: `Name der Agentur*`,
brand_creation_info_agency_name_question: `Wie heißt deine Agentur?`,
brand_creation_info_brand_audio: `Brand Aussprache`,
brand_creation_info_brand_logo: `Logo der Brand`,
brand_creation_info_brand_name: `Name der Brand*`,
brand_creation_info_brand_name_question: `Wie heißt deine Brand?`,
brand_creation_info_country_question: `In welchem Land ist dein Firmensitz?`,
brand_creation_info_industry: `Branche`,
brand_creation_info_industry_option_accessories: `Accessoires (Schmuck, Uhren etc.)`,
brand_creation_info_industry_option_agency: `Werbeagentur`,
brand_creation_info_industry_option_apparel: `Fashion & Accessoires`,
brand_creation_info_industry_option_beauty: `Beauty & Make-Up`,
brand_creation_info_industry_option_beverages: `Getränke`,
brand_creation_info_industry_option_books: `Bücher & Schreibwaren`,
brand_creation_info_industry_option_business: `Dienstleistungen`,
brand_creation_info_industry_option_digital_products: `Digitale Produkte und Apps`,
brand_creation_info_industry_option_diverse: `Diverse`,
brand_creation_info_industry_option_education: `Bildung`,
brand_creation_info_industry_option_entertainment: `Unterhaltung`,
brand_creation_info_industry_option_finance: `Finanzen & Versicherungen`,
brand_creation_info_industry_option_financial: `Finanzwesen`,
brand_creation_info_industry_option_fitness: `Fitness`,
brand_creation_info_industry_option_food: `Food`,
brand_creation_info_industry_option_games: `Gaming`,
brand_creation_info_industry_option_health: `Gesundheit & Fitness`,
brand_creation_info_industry_option_interior_diy: `Interior & DIY`,
brand_creation_info_industry_option_jobs: `Jobs`,
brand_creation_info_industry_option_luxury: `Luxury`,
brand_creation_info_industry_option_mobile: `Mobile Apps`,
brand_creation_info_industry_option_mommy_kids: `Mommy & Kids`,
brand_creation_info_industry_option_other: `Andere`,
brand_creation_info_industry_option_pets: `Tierbedarf`,
brand_creation_info_industry_option_technik: `Technik`,
brand_creation_info_industry_option_travel: `Travel`,
brand_creation_section_agency_info: `Agentur Info`,
brand_creation_section_brand_info: `Brand Info`,
brand_creation_section_contact: `Ansprechperson`,
brand_creation_section_users: `Mitarbeiter`,
brand_creation_upload_logo_error: `Ups! Dein Logo konnte nicht hochgeladen werden, deine Brand wird dennoch erstellt. Bitte melde dich bei uns über ugc@stylink.com, damit wir dein Logo nachtragen können.`,
brand_creation_users_body: `Du kannst anderen Benutzern folgende Rollen zuteilen:
          <br /> <br />
          <b>Admin:</b> Kann Brandeinstellungen sowie Kampagnen bearbeiten.
          <br />
          <b>Marketing:</b> Kann Kampagnen bearbeiten.
          <br />
          <b>Analyst:</b> Kann Kampagnen ansehen.
          <br />
          <b>Buchhaltung:</b> Kann lediglich Rechnungsübersichten einsehen.`,
brand_creation_users_email_label: `E-Mail-Adresse`,
brand_creation_users_invite: `Einladen`,
brand_creation_users_role_accountant: `Buchführung`,
brand_creation_users_role_admin: `Admin`,
brand_creation_users_role_analyst: `Analyst`,
brand_creation_users_role_label: `Rolle`,
brand_creation_users_role_marketer: `Marketing`,
brand_creation_users_title: `Weitere Nutzer`,
brand_creation_yup_brand_name: `Der Brand Name muss angegeben werden.`,
brand_creation_yup_email: `Die E-Mail-Adresse muss angegeben werden. `,
brand_creation_yup_email1: `Ungültige E-Mail-Adresse.`,
brand_creation_yup_email2: `Die E-Mail-Adresse muss angegeben werden.`,
brand_creation_yup_firstname: `Der Vorname muss angegeben werden.`,
brand_creation_yup_industry: `Die Branche muss angegeben werden.`,
brand_creation_yup_mobile: `Telefonnummer muss angegeben werden.`,
brand_creation_yup_mobile1: `Ungültige Telefonnummer.`,
brand_creation_yup_role: `Die Rolle muss angegeben werden.`,
brand_creation_yup_surname: `Der Nachname muss angegeben werden.`,
brand_invoices_credit: `Stornorechnung`,
brand_invoices_debit: `Abbuchung`,
brand_invoices_download: `Rechnung herunterladen`,
brand_invoices_download_error: `Bitte melde dich bei unserem Support.`,
brand_invoices_empty: `Du hast noch keine Rechnungen.`,
brand_invoices_error: `Ups! Ein unbekannter Fehler ist aufgetreten.`,
brand_invoices_missing_date: `Das Datum muss angegeben werden.`,
brand_invoices_no_auth: `Du hast noch keinen Berechtigungszugriff für diesen Bereich.`,
brand_invoices_number: `Rechnungsnummer`,
brand_invoices_table_date: `Datum`,
brand_invoices_table_sum: `Summe`,
brand_invoices_table_type: `Betreff`,
brand_payment_balance_current: `Aktuelles Guthaben:`,
brand_payment_creditcard_title: `Kreditkarte`,
brand_payment_credit_title: `Guthaben`,
brand_payment_method_choose: `Wähle dein Zahlungsmittel aus.`,
brand_payment_method_choose_error: `Ups! Es ist ein Fehler aufgetreten.`,
brand_payment_method_no_role: `Du bist nicht berechtigt, das Zahlungsmittel zu ändern.`,
brand_payment_method_saved: (last4: P) => `Dein angegebenes Zahlungsmittel endet mit den Ziffern ${last4 as S}.` as const,
brand_payment_method_saved_nothing: `Du hast noch kein Zahlungsmittel angegeben.`,
brand_payment_method_title: `Zahlungsmittel`,
brand_payment_save: `Zahlungsmittel speichern`,
brand_payment_sepa_agreement: `Ich ermächtige/Wir ermächtigen (A) stylink Social Media GmbH und Stripe, Zahlungen von meinem/unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise ich mein/weisen wir unser Kreditinstitut an, die von stylink Social Media GmbH auf mein/unser Konto angewiesenen Lastschriften einzuziehen. Hinweis: Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages beantragen. Es gelten dabei die mit meinem/unserem Kreditinstitut vereinbarten Bedingungen.`,
brand_payment_sepa_title: `SEPA`,
brand_settings_nav_invoices: `Rechnungen`,
brand_settings_nav_payment: `Zahlung`,
brand_settings_nav_settings: `Einstellungen`,
brand_settings_nav_users: `Nutzende`,
brand_users_campaign_subtitle: `Brand Management`,
brand_users_campaign_title: `Kampagne`,
brand_users_email_title: `E-Mail-Adresse`,
brand_users_error: `Ups! Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.`,
brand_users_info: `<b>Du kannst anderen Benutzern folgende Rollen zuteilen: </b>
      <br />
      <br />
      <span>
        <b>Admin:</b> Kann Brandeinstellungen sowie Kampagnen bearbeiten.
      </span>
      <br />
      <span>
        <b>Marketing:</b> Kann Kampagnen bearbeiten.
      </span>
      <br />
      <span>
        <b>Analyst:</b> Kann Kampagnen ansehen.
      </span>
      <br />
      <span>
        <b>Buchhaltung:</b> Kann lediglich Rechnungsübersichten einsehen.
      </span>`,
brand_users_insert: `Nutzer hinzufügen`,
brand_users_insert_admin_only: `Nur Admins können Nutzer hinzufügen oder entfernen.`,
brand_users_insert_invalid_email: `Keine gültige E-Mail-Adresse.`,
brand_users_invite: `Einladen`,
brand_users_table_email: `E-Mail`,
brand_users_table_role: `Rolle`,
campaign_creation_age_label: `Alter (Mehrfachnennung möglich)`,
campaign_creation_budget_creator: (number: P) => `${number as S} Content Creator` as const,
campaign_creation_budget_creators: (number: P) => `${number as S} Content Creators` as const,
campaign_creation_budget_info1: `Deine Bestellung wird von`,
campaign_creation_budget_info2: `geliefert.`,
campaign_creation_budget_question: `Wie viele Videos möchtest du in Auftrag geben?`,
campaign_creation_budget_tip: `Wir empfehlen, dass du dir dasselbe Videokonzept von mehreren Creators ausarbeiten lässt, um dann aus den besten Inhalten auswählen zu können.`,
campaign_creation_budget_video_count: `Anzahl an Videos`,
campaign_creation_budget_video_count_tip: `Arbeite mindestens mit 3-5 Creators zusammen, um die besten Ergebnisse für deine Werbekampagne zu erzielen.`,
campaign_creation_budget_videos_label: `Videos`,
campaign_creation_content_ctas_add_button: (c: P,i: P) => `Füge alternativen CTA hinzu (${c as S}${i as S})` as const,
campaign_creation_content_ctas_placeh: (i: P) => `Alternativer CTA ${i as S}` as const,
campaign_creation_content_ctas_placeholder1: `Pushe deine Kaufempfehlung, indem du beispielsweise einen Gutscheincode angibst.`,
campaign_creation_content_ctas_title: `Ende / Call-to-Action (Optional)`,
campaign_creation_content_hooks_add_button: (c: P,i: P) => `Füge alternativen Hook hinzu (${c as S}${i as S})` as const,
campaign_creation_content_hooks_placeh: (i: P) => `Alternativer Hook ${i as S}` as const,
campaign_creation_content_hooks_placeholder1: `Beginne dein Video im Selfie-Modus mit einer herzlichen Begrüßung, um sofort positive Vibes zu verbreiten.`,
campaign_creation_content_hooks_title: `Intro / Hook (Optional)`,
campaign_creation_content_mentions_desc: `Lege hier fest, welche Informationen von den Creators erwähnt werden sollen.`,
campaign_creation_content_mentions_label: (i: P) => `Info ${i as S}` as const,
campaign_creation_content_mentions_title: `Zusatzinfo (Optional)`,
campaign_creation_content_moodboard_body: `Lade bis zu sechs Bilder oder Videos hoch, die den Creators als Inspiration dienen sollen. Du kannst außerdem definieren, wie du dir deine Untertitel wünschst.`,
campaign_creation_content_moodboard_body1: `Zeige den Creators, wie du dir deine Videos vorstellst.`,
campaign_creation_content_moodboard_body2: `Füge bis zu sechs Bilder oder Videos hinzu, die den Creators als Inspiration dienen sollen.`,
campaign_creation_content_moodboard_dont_use: `Beispiele nicht verwenden`,
campaign_creation_content_moodboard_title: `Beispielvideos/ Bilder (Optional)`,
campaign_creation_content_moodboard_use: `Beispiele verwenden`,
campaign_creation_content_nano_storyboard_desc: `Ein Experte von unserem Team erstellt ein Storyboard für die Creators. `,
campaign_creation_content_nano_storyboard_label: `stylink UGC Storyboard`,
campaign_creation_content_scene_placeh: `Szenen`,
campaign_creation_content_scenes_desc: `Beschreibe in einzelnen Szenen, wie das Video aufgebaut sein soll z.B. Umgebung, Wortwahl, Tonalität, Szenen (wesentliche Informationen für Creators).`,
campaign_creation_content_scenes_placeholder1: `Szene 1: [gewünschte Perspektive, Umgebungsinformation] 
“Wörtliche Rede in Anführungsstrichen einsetzen” 

Szene 2: [Anweisungen für Creators] 
“Wörtliche Rede in Anführungsstrichen einsetzen” 
 
Szene 3: [Anweisungen für Creators] 
"Wörtliche Rede in Anführungsstrichen einsetzen” 
 
Beschreibe bei Bedarf weitere Szenen und ergänze wichtige Inhalte.`,
campaign_creation_content_scenes_title: `Szenen`,
campaign_creation_content_self_service_desc: `Du legst die Szenen und weitere Inspirationen für die Creator selbst fest.`,
campaign_creation_content_self_service_label: `Self-Service`,
campaign_creation_create: `CHECKOUT`,
campaign_creation_disclaimer_body: `Hinweis: Kommt es zu Verzögerungen von deiner Seite aus, beeinflussen diese ebenfalls das Lieferdatum.`,
campaign_creation_disclaimer_contact_check_label: `Ich bestätige, dass ich außerhalb dieser Plattform keinen Kontakt mit den Creators aufnehmen werde.`,
campaign_creation_disclaimer_info_check_label: `Ich nehme diese Information zur Kenntnis.`,
campaign_creation_disclaimer_no_payment_check: `Hinweis: Keine Rechnung ausstellen. Eine Rechnung wurde bereits außerhalb von Stripe erstellt (Admin).`,
campaign_creation_disclaimer_title: `Voraussichtliches Lieferdatum`,
campaign_creation_error: `Oh nein, du hast etwas vergessen!`,
campaign_creation_format_title: `Videoformat`,
campaign_creation_gender_any: `Alle Geschlechter`,
campaign_creation_gender_diverse: `Diverse`,
campaign_creation_gender_female: `Weiblich`,
campaign_creation_gender_label: `Geschlecht`,
campaign_creation_gender_male: `Männlich`,
campaign_creation_general_campaign_image_label: `Produktbild`,
campaign_creation_general_product_desc_label: `Beschreibe bitte kurz dein Produkt.*`,
campaign_creation_general_product_info: `Produktinformationen`,
campaign_creation_general_product_info_body: `Beschreibe, welches Produkt die Creator bewerben sollen.`,
campaign_creation_general_product_link_label: `Link zum Produkt/zur Website.*`,
campaign_creation_general_product_name_label: `Produktname*`,
campaign_creation_general_product_price_disclaimer: `Bitte gebe hier deine Selbstkosten anstelle des Verkaufspreises an. `,
campaign_creation_general_product_price_label: (currency: P) => `Wert in ${currency as S}*` as const,
campaign_creation_general_product_type: `Produktart`,
campaign_creation_general_producttype_digital_desc: `Für digitale Produkte (App, Software, etc.)`,
campaign_creation_general_producttype_digital_title: `Digital`,
campaign_creation_general_producttype_physical_desc: `Versende dein Produkt an die ausgewählten Creator. Sie können es nach Abschluss des Auftrags behalten.`,
campaign_creation_general_producttype_physical_title: `Physisch`,
campaign_creation_invite_body: `Mit welchen Creators möchtest du erneut zusammenarbeiten?`,
campaign_creation_invite_title: `Creator einladen`,
campaign_creation_language_english: `Englisch`,
campaign_creation_language_german: `Deutsch`,
campaign_creation_language_label: `Videosprache`,
campaign_creation_persist_body: `Du hast noch einen Auftrag zwischengespeichert. Möchtest du diese Daten laden?`,
campaign_creation_persist_delete: `Löschen`,
campaign_creation_persist_title: `Zwischengespeicherte Daten laden`,
campaign_creation_preferences: `Produktpräferenzen`,
campaign_creation_preferences_no: `Das Produkt ist für alle geeignet.`,
campaign_creation_preferences_placeh: `Beispiel: Welche Kleidergröße trägst du?`,
campaign_creation_preferences_question: (i: P) => `Frage ${i as S}` as const,
campaign_creation_preferences_tooltip: `Benötigst du bestimmte Angaben der Creators, wie z.B. die Schuhgröße, um ihnen das passende Produkt zukommen zu lassen? Formuliere hier deine Fragen, um alle notwendigen Informationen zu erhalten.`,
campaign_creation_preferences_yes: `Ich benötige weitere Informationen (zB. Schuhgröße) von den Creators.`,
campaign_creation_requirements: `Welche Creators sollen dein Video umsetzen?`,
campaign_creation_section_brand: `Info der Brand`,
campaign_creation_section_budget: `Anzahl`,
campaign_creation_section_content: `Inhalte`,
campaign_creation_section_invite: `Einladungen`,
campaign_creation_section_product: `Produkt`,
campaign_creation_section_requirements: `Anforderungen`,
campaign_creation_section_video_type: `Videotyp`,
campaignCreationTimelineApplications: (endDate: P) => `Bewerber auswählen\nbis ${endDate as S}` as const,
campaignCreationTimelineDelivery: (endDate: P) => `Produkt versenden\nbis ${endDate as S}` as const,
campaignCreationTimelineFeedback: (endDate: P) => `Video erhalten\nbis ${endDate as S}` as const,
campaignCreationTimelineVideos: (endDate: P) => `Feedback geben (Optional)\nbis ${endDate as S}` as const,
campaign_creation_update: `UPDATE SPEICHERN`,
campaign_creation_upload_brand_audio_error: `Ups! Dein Audio konnte nicht hochgeladen werden, deine Brand wird dennoch erstellt. Bitte melde dich bei uns über ugc@stylink.com, damit wir dein Bild nachtragen können.`,
campaign_creation_upload_brand_logo_error: `Ups! Dein Bild konnte nicht hochgeladen werden, deine Brand wird dennoch erstellt. Bitte melde dich bei uns über ugc@stylink.com, damit wir dein Bild nachtragen können.`,
campaign_creation_upload_campaign_image_error: `Ups! Dein Bild konnte nicht hochgeladen werden, deine Kampagne wird dennoch erstellt. Bitte melde dich bei uns über ugc@stylink.com, damit wir dein Bild nachtragen können.`,
campaign_creation_upload_moodboard_error: `Fehler beim Hochladen der Beispiele.`,
campaign_creation_video_duration_15: `10 - 15 Sekunden`,
campaign_creation_video_duration_30: `25 - 30 Sekunden`,
campaign_creation_video_duration_60: `60 Sekunden`,
campaign_creation_video_duration_price: (c: P,price: P) => `${c as S}${price as S} / Sek` as const,
campaign_creation_video_duration_title: `Videodauer`,
campaign_creation_video_features_info: `Diese Zusatzleistungen werden direkt von unserem Team hinzugefügt.`,
campaign_creation_video_features_title: `Zusatzleistungen (optional)`,
campaign_creation_video_format_fullscreen: `Querformat`,
campaign_creation_video_format_horizontal: `Querformat`,
campaign_creation_video_format_horizontal_platform: `YouTube`,
campaign_creation_video_format_vertical: `Hochformat`,
campaign_creation_video_format_vertical_platform: `Instagram | TikTok`,
campaign_creation_video_type_ad_desc: `Ideal für Werbeanzeigen auf Social Media.`,
campaign_creation_video_type_ad_title: `UGC Video`,
campaign_creation_video_type_example_title: `Beispielvideo`,
campaign_creation_video_type_premium_desc: `Professionelle UGC Videos von erfahrenen Creators mit bestehendem Know-How in Tonqualität, Beleuchtung, etc.`,
campaign_creation_video_type_premium_title: `UGC Premium-Video`,
campaign_creation_video_type_title: `Videotyp`,
campaign_creation_yup_age: `Das Alter muss angegeben werden.`,
campaign_creation_yup_brand_logo: `Das Logo muss hochgeladen werden.`,
campaign_creation_yup_campaign_image: `Das Produktbild muss hochgeladen werden.`,
campaign_creation_yup_contact_check: `Bitte bestätige uns, dass du keinen Kontakt mit den Creators außerhalb dieser Plattform aufnimmst.`,
campaign_creation_yup_country: `Das Land muss angegeben werden.`,
campaign_creation_yup_gender: `Das Geschlecht muss angegeben werden.`,
campaign_creation_yup_hooks_ctas_limit: `Es sind maximal 200 Zeichen möglich.`,
campaign_creation_yup_hooks_product_urls_limit: `Maximal 200 Zeichen möglich`,
campaign_creation_yup_info_check: `Bitte bestätige uns, dass du die Information zur Kenntnis genommen hast.`,
campaign_creation_yup_language: `Die Sprache muss angegeben werden.`,
campaign_creation_yup_link1: `Ungültige URL`,
campaign_creation_yup_link2: `Die Website muss angegeben werden.`,
campaign_creation_yup_locale: `Die Zustellungsart muss angegeben werden.`,
campaignCreationYupMetionsLimit: `Maximal 120 Zeichen möglich`,
campaign_creation_yup_name: `Der Name muss angegeben werden.`,
campaign_creation_yup_number_of_slots1: `Es muss mindestens ein Video ausgewählt werden.`,
campaign_creation_yup_number_of_slots2: `Die Anzahl der Videos muss angegeben werden.`,
campaign_creation_yup_number_of_slots3: `Es können maximal 10 Videos ausgewählt.`,
campaign_creation_yup_price1: `Der Preis muss als Zahl angegeben werden.`,
campaign_creation_yup_price2: `Der Preis muss mehr als 0€ betragen.`,
campaign_creation_yup_price3: `Der Preis muss angegeben werden.`,
campaign_creation_yup_product_desc: `Die Beschreibung muss angegeben werden.`,
campaign_creation_yup_product_type: `Der Produkttyp muss angegeben werden.`,
campaign_creation_yup_scenes1: `Die Szenen müssen angegeben werden.`,
campaign_creation_yup_scenes2: `Es muss mindestens eine Szene ausgewählt werden.`,
campaign_creation_yup_video_duration: `Die Videodauer muss angegeben werden.`,
campaign_creation_yup_video_format: `Das Videoformat muss angegeben werden.`,
campaign_creation_yup_video_type: `Der Videotyp muss angegeben werden.`,
campaign_order_photos_description: `Lasse kreative Bilder in jedem gängigen Format zum Video erstellen, um deine Social-Media-Accounts, Newsletter und Online-Shop ansprechend zu gestalten.`,
campaign_order_photos_question: `Wie viele Fotos benötigst du? (24€/ Foto)`,
campaign_order_photos_text_field: `Beschreibe die einzelnen Szenen, wie die Fotos gemacht werden sollen.`,
campaign_order_photos_title: `Fotos gleich mitbestellen`,
campaign_overview_accept_influencer: `AKZEPTIEREN`,
campaign_overview_accept_influencer_error: `Ups! Bei der Annahme ist ein Fehler aufgetreten.`,
campaignOverviewApplicantsEmptyBody: `Wo sind denn alle?`,
campaignOverviewApplicantsEmptyTitle: `Du hast keine BewerberInnen für diese Kampagne`,
campaign_overview_book_influencer: `BUCHEN`,
campaign_overview_check_empty_body: `Es gibt noch keine Videos für deine Kampagne.`,
campaign_overview_check_empty_done_body: `Keine neuen Inhalte zum Überprüfen.`,
campaign_overview_check_empty_done_title: `Alles erledigt!`,
campaign_overview_check_empty_title: `Ups! Es sind noch keine Inhalte verfügbar`,
campaign_overview_check_give_feedback: `Gib uns gern ein Feedback.`,
campaign_overview_check_info: `Gebuchte Zusatzleistungen, wie Untertitel, Musik und Animationen werden gesondert von unserem Team akzeptiert und hinzugefügt.`,
campaign_overview_content_being_edited: `In Bearbeitung`,
campaign_overview_content_download: `Downloaden`,
campaign_overview_content_duplicate_body: `Dupliziere jetzt deinen Auftrag, um weitere UGC-Videos zu erhalten.`,
campaign_overview_content_duplicate_button: `Auftrag duplizieren`,
campaign_overview_content_duplicate_title: `Weitere Videos erhalten`,
campaign_overview_content_empty_body: `Du hast noch keine fertigen Videos.`,
campaign_overview_content_empty_title: `Keine Videos verfügbar`,
campaignOverviewContentGiveRatingBody: `Anhand deines Feedbacks, können wir dir bei künftigen Aufträgen entsprechende Creator anzeigen.`,
campaignOverviewContentGiveRatingButton: `Bewertung senden`,
campaignOverviewContentGiveRatingPholder: `Lass den Creator wissen, was er besser machen kann.`,
campaignOverviewContentGiveRatingTitle: `Bewertung abgeben`,
campaign_overview_content_raw_download: `Rohdatei downloaden`,
campaign_overview_content_upgraded_message: `Erfolgreich gebucht!`,
campaign_overview_content_upgrade_label: `Upgrade buchen`,
campaign_overview_creators_address: `Adresse`,
campaign_overview_creators_being_edited: `In Bearbeitung`,
campaign_overview_creators_check_content: `Content überprüfen`,
campaign_overview_creators_creator: `Creator`,
campaign_overview_creators_delete_button: `Entfernen`,
campaign_overview_creators_disclaimer: `Falls möglich, hinterlege die E-Mail ugc@stylink.com bei deinem Versandpartner als Kontakt, damit wir Lieferschwierigkeiten vorbeugen können.`,
campaign_overview_creators_empty_body: `Du hast noch keine aktiven Creator.`,
campaign_overview_creators_empty_title: `Keine aktiven Creator`,
campaign_overview_creators_finished: `Abgeschlossen`,
campaign_overview_creators_give_feedback: (endDate: P) => `Gib uns gern ein Feedback bis ${endDate as S}.` as const,
campaign_overview_creators_mail: (brandName: P,firstname: P) => `mailto:ugc@stylink.com?subject=Problem Meldung von ${brandName as S} mit stylink UGC ${firstname as S}` as const,
campaign_overview_creators_mail_button: `Melden`,
campaign_overview_creators_make_2nd_content: (endDate: P) => `Der Creator überarbeitet den Content bis ${endDate as S}.` as const,
campaign_overview_creators_product_preferences: `Produktpräferenzen`,
campaign_overview_creators_send_until: (endDate: P) => `Versenden bis ${endDate as S}.` as const,
campaign_overview_creators_shipping_button: `Versanddaten`,
campaign_overview_creators_status: `Status`,
campaign_overview_creators_success: `Alles erledigt!`,
campaign_overview_creators_title1: `Creator`,
campaign_overview_creators_title2: `Produkt versendet`,
campaign_overview_creators_title3: `Content produziert`,
campaign_overview_creators_todo: `To-Do`,
campaign_overview_creators_wait_for_feedback: `Der Content wird von unserem Team geprüft.`,
campaign_overview_make_content: (endDate: P) => `Der Creator produziert den Content bis ${endDate as S}.` as const,
campaign_overview_no_more_codes: `Es sind aktuell keine Gutscheincodes mehr verfügbar. Bitte stelle neue Codes ein, bevor du weitere Creator annimmst.`,
campaign_overview_no_more_slots: `Ups! Du hast keine freien Videos mehr verfügbar.`,
campaign_overview_onsent_error: `Ups! Beim Senden ist ein Fehler aufgetreten.`,
campaign_overview_participant: `Creator:`,
campaign_overview_stats_body1: `Creator laden ein Bewerbungsvideo hoch, in dem sie ein Produkt ihrer Wahl bewerben und damit einen Einblick in ihre Content Creation geben. Jede Buchung ist verbindlich.`,
campaign_overview_stats_body2: (daysForApplying: P) => `Bewerbungen bleiben ${daysForApplying as S} Tage bestehen.` as const,
campaign_overview_stats_title: `Hinweis:`,
campaignOverviewStepApplications: `Bewerbungen`,
campaignOverviewStepCheck: `Content Überprüfen`,
campaignOverviewStepContent: `Mein Content`,
campaignOverviewStepCreators: `Meine Creator`,
campaign_photo_label: `Foto`,
campaign_photos_label: `Fotos`,
cancel: `Ablehnen`,
change: `Ändern`,
choose_unboxing_style: `Beispielvideos, Stil auswählen`,
classic_video_title: `Classic UGC video`,
clear: `Klar!`,
common_saved: `Erfolgreich gespeichert!`,
copied: `Kopiert!`,
creators_status_deleted_firstupload: `Abgebrochen`,
creators_status_deleted_secondupload: `Das Feedback wurde nicht umgesetzt.`,
dashboard_campaign_applicants: `Bewerbungen`,
dashboard_campaign_finished: `Abgeschlossen`,
dashboard_campaign_not_verified: `Kampagne wird geprüft`,
dashboard_campaign_offline: `Offline`,
dashboard_campaign_online: `Online`,
dashboard_campaign_participants: `Creator`,
dashboard_create_ugc_body: `Lasse hochqualitiative UGC-Videos von authentischen Content Creators für dein Unternehmen erstellen!`,
dashboard_create_ugc_button: `Auftrag erstellen`,
dashboard_create_ugc_title: `UGC Video`,
dashboard_empty_body: (price: P,currency: P) => `Lass uns das ändern! Erstelle jetzt deine ersten UGC Aufträge ab ${price as S}${currency as S}.` as const,
dashboard_empty_title: `Ziemlich leer hier`,
dashboard_empty_tour: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/platform_tour.mp4`,
dashboard_title: `Deine Aufträge`,
deny_dialog_choose_feedback: `Lasse deinem Creator ein Feedback da.`,
deny_dialog_disclaimer: `Du kannst nur einmalig Änderungen von den Creators anfordern. Verdeutliche also genau, welche Anpassungen du dir wünscht.`,
deny_dialog_feedback: (number: P) => `Feedback ${number as S}` as const,
deny_dialog_max: `Es sind maximal fünf Feedback-Kommentare möglich.`,
deny_dialog_send_feedback: `Feedback senden`,
deny_dialog_title: `Änderungen anfordern`,
denyReasonBadAge: `Zu jung oder zu alt`,
denyReasonBadQuality: `Qualität nicht ausreichend`,
denyReasonBadVoicing: `Stimme passt nicht`,
denyReasonIrrelevant: `Passt nicht zur Marke`,
denyReasonOther: `Sonstiges`,
deny_reason_question: `Bitte erläutere uns, warum und an welcher Stelle du dir Anpassungen wünscht.`,
deselect: `Abwählen`,
discount: `Rabattcode`,
drag_and_drop_body: `Füge maximal sechs Bilder oder Videos hinzu`,
drag_and_drop_caption: `File: MP4, JPG, PNG; min. 400x400`,
drag_and_drop_title: `Drag & Drop oder File auswählen`,
error: `Fehler aufgetreten`,
error2: `Ups! Ein unerwarteter Fehler ist aufgetreten.`,
error_reload_body: `Ups! Ein unerwarteter Fehler ist aufgetreten. Bitte lade die Seite erneut.`,
feedbackDialogDescription: `Hilf uns dabei, deine User Experience zu verbessern! Wir schätzen dein Feedback, damit wir unsere Plattform noch besser machen können!`,
feedbackDialogSubmitButton: `Senden`,
feedbackDialogSuccess: `Feedback erfolgreich gesendet!`,
feedbackDialogTextfield: `Feedback`,
feedbackDialogTitle: `Feedback geben`,
full_slots_coupon_dialog_body: `Für den nächsten Creator, den du für diesen Auftrag buchst, erhältst du`,
full_slots_coupon_dialog_body_empty: `Erhältst du heute noch Bewerbungen für deinen Auftrag, kannst du diese Creator buchen. Für den nächsten Creator, den du in diesem Auftrag buchst, erhältst du`,
full_slots_coupon_dialog_expiration: `Dein Gutscheincode lautet:`,
full_slots_coupon_dialog_title: `Hier ist dein Gutscheincode`,
full_slots_coupon_dialog_valid_until: `Gültig bis:`,
full_slots_normal_dialog_body: `Du hast jetzt die Möglichkeit weitere Creator zu buchen.

Alle offenen Bewerbungen werden heute Nacht automatisch abgelehnt.`,
full_slots_normal_dialog_title: `Weitere Creator buchen`,
influencer_dialog_age: `Alter:`,
influencer_dialog_birth_place: `Wohnort:`,
influencer_dialog_country: `Land:`,
influencer_dialog_deny_reason: `Wähle einen Grund aus`,
inspo_classic_desc_1: `geeignet für Social Media Ads und organischen Content`,
inspo_classic_desc_2: `Produkt und Creator stehen im Fokus`,
inspo_classic_desc_3: `ideal für physische Produkte, die angewendet werden`,
inspo_classic_title_1: `Klassisches`,
inspo_classic_title_2: `UGC Video`,
inspo_digital_desc_1: `geeignet für Apps und Software-Lösungen`,
inspo_digital_desc_2: `Anwendung steht im Fokus`,
inspo_digital_desc_3: `ideal für Food, Versicherungen, Travel`,
inspo_digital_title_1: `Digitales`,
inspo_digital_title_2: `UGC Video`,
inspo_industry_desc_1: `geeignet für Bau - und Handwerksindustrie`,
inspo_industry_desc_2: `stelle dem Creator Cut Outs zur Verfügung, um einen „Vorher-Nachher“ Effekt zu bewirken`,
inspo_industry_desc_3: `ideal für größere Produkte`,
inspo_industry_title_1: `Industrie`,
inspo_industry_title_2: `UGC Video`,
inspo_unboxing_desc_1: `geeignet für E-Commerce & Online Marktplätze `,
inspo_unboxing_desc_2: `“Unboxing Effekt” steht im Fokus`,
inspo_unboxing_desc_3: `ideal für soundless Videos mit Creator oder faceless Videos`,
inspo_unboxing_title_1: `Unboxing & Gesichtslos `,
inspo_unboxing_title_2: `UGC Video`,
intro_campaign_overview_creators_send: (date: P) => `Bitte trage hier bis zum ${date as S} die Sendungsnummer ein.` as const,
intro_campaign_overview_creators_send_title: (date: P) => `Bitte trage hier bis zum ${date as S} den Tracking-Link ein.` as const,
invalid_link: `Der Link ist ungültig`,
login_agb_button: `AGB`,
login_agb_link: `https://ugc.stylink.com/de/agb`,
login_button: `Login`,
login_code_label: `Code`,
login_data_protection_button: `Datenschutzerklärung`,
login_email_label: `E-Mail`,
login_error: `Ups! Ein unerwarteter Fehler ist aufgetreten.`,
login_error_email_exists: `Die angegebene E-Mail-Adresse existiert bereits.`,
login_error_referral_code: `Empfehlungscode ungültig`,
login_error_too_many_attempts: `Zu viele Anmeldeversuche. Bitte versuche es später erneut.`,
login_error_wrong_credentials: `Benutzername und Passwort stimmen nicht überein.`,
login_forgot_password_button: `Passwort vergessen?`,
login_login_button: `Einloggen`,
login_login_text: `Du hast noch keinen Account?`,
login_password_label: `Passwort`,
login_privacy_link: `https://ugc.stylink.com/de/datenschutzerklaerung/`,
login_register_button: `Erstelle einen Account`,
login_register_confirmation_part1: `Ich stimme den`,
login_register_confirmation_part2: `AGB`,
login_register_confirmation_part3: `und der`,
login_register_confirmation_part4: `Datenschutzerklärung`,
login_register_confirmation_part5: `zu.`,
login_register_firstname_label: `Vorname`,
login_register_referral_code: `Empfehlungscode`,
login_register_submit_button: `Registrieren`,
login_register_surname_label: `Nachname`,
login_register_text: `Du hast bereits einen Account?`,
login_register_yup_code1: `Bitte gib den Code aus deiner E-Mail.`,
login_register_yup_confirmation: `Die AGB und Datenschutzerklärung müssen bestätigt werden.`,
login_register_yup_firstname1: `Der Vorname muss länger als zwei Zeichen sein.`,
login_register_yup_surname1: `Der Nachname muss länger als zwei Zeichen sein.`,
login_reset_password_body: `Kein Problem! Nenne uns bitte deine E-Mail-Adresse und wir senden dir einen Code zum Zurücksetzen deines Passworts zu.`,
login_reset_password_title: `Passwort zurücksetzten`,
login_slide_desc1: `Erstelle einen Auftrag ganz nach deinen Vorstellungen und lege das Videoskript, Videolänge, Erwähnungen und vieles mehr fest.`,
login_slide_desc2: `Creator können sich auf deinen Auftrag bewerben und du entscheidest, mit wem du zusammenarbeiten möchtest.`,
login_slide_desc3: `Sende den von dir ausgewählten Creators dein Produkt zu, damit sie deine Videos erstellen können.`,
login_slide_desc4: `Im Anschluss erhältst deine Videos. Solltest du Anpassungswünsche haben, hast du die Möglichkeit auf eine Änderungsschleife.`,
login_slide_title1: `Erstelle einen Auftrag`,
login_slide_title2: `Erhalte Bewerbungen und wähle aus`,
login_slide_title3: `Versende dein Produkt`,
login_slide_title4: `Erhalte deine Videos`,
login_yup_email1: `Ungültige E-Mail-Adresse.`,
login_yup_email2: `E-Mail-Adresse muss angegeben werden. `,
login_yup_password1: `Das Passwort muss mindestens acht Zeichen lang sein.`,
login_yup_password2: `Das Passwort muss mindestens einen Kleinbuchstaben enthalten.`,
login_yup_password3: `Das Passwort muss mindestens einen Großbuchstaben enthalten.`,
login_yup_password4: `Das Passwort muss mindestens eine Zahl enthalten.`,
login_yup_password5: `Passwort muss angegeben werden.`,
moodboard_title: `Inspiration`,
more: `mehr`,
most_popular: `am beliebtesten`,
next: `Weiter`,
no: `Nein`,
no_auth_body: `Es scheint, als hättest du keine Berechtigungen, um auf diesen Bereich zuzugreifen.
                <br />
Andernfalls kannst du versuchen, die Seite erneut zu laden.`,
no_auth_title: `Leider geht es hier nicht weiter`,
onboarding_other: `SONSTIGES`,
onboardingPlattformForAgencyButton: `FÜR AGENTUREN`,
onboardingPlattformForBrandsButton: `FÜR BRANDS`,
onboarding_referral: `EMPFEHLUNG`,
onboarding_title: `Wie hast du von uns erfahren?`,
payment_modal_cancel: `ABBRECHEN`,
payment_modal_choose_method: `Wähle deine Zahlungsart:`,
payment_modal_choose_method_creditcard: `Kreditkarte`,
payment_modal_choose_method_sepa: `SEPA Lastschrift`,
payment_modal_coupon_invalid: `Dieser Rabattcode ist ungültig.`,
payment_modal_method_choose_error: `Ups! Es ist ein Fehler bei der Eingabe aufgetreten.`,
payment_modal_method_company_info_address: `Straße, Hausnummer`,
payment_modal_method_company_info_city: `Stadt`,
payment_modal_method_company_info_country: `Land`,
payment_modal_method_company_info_name: `Firmenname`,
payment_modal_method_company_info_title: `Rechnungsanschrift`,
payment_modal_method_company_info_vatnumber: `USt-ID`,
payment_modal_method_company_info_vatnumber_check: `Mein Unternehmen besitzt eine gültige USt-ID.`,
payment_modal_method_company_info_zip: `PLZ`,
payment_modal_pay: `JETZT BEZAHLEN`,
payment_modal_title: `Deine Bestellung`,
payment_modal_yup_company_address: `Straße und Hausnummer müssen angegeben werden.`,
payment_modal_yup_company_city: `Stadt muss angegeben werden.`,
payment_modal_yup_company_country: `Land muss angegeben werden.`,
payment_modal_yup_company_name: `Firmenname muss angegeben werden.`,
payment_modal_yup_company_payment_method: `Bezahlmethode muss ausgewählt werden.`,
payment_modal_yup_company_vat_number1: `USt-ID muss angegeben werden.`,
payment_modal_yup_company_vat_number2: (country: P) => `Ungültige USt-ID. für ${country as S}.` as const,
payment_modal_yup_company_zip: `Postleitzahl muss angegeben werden.`,
preferences_dialog_empty: `Ups! Es ist ein unerwarteter Fehler aufgetreten.`,
preferences_dialog_no_answer: `Keine Antwort`,
preferences_dialog_title: `Produktpräferenzen`,
preview_invoice_body: `Dein Preis setzt wie folgt zusammen:`,
preview_invoice_brutto: `Brutto`,
preview_invoice_count: `Anzahl der Videos`,
preview_invoice_count_photos: `Anzahl der Bilder`,
preview_invoice_discount: `Rabatt`,
preview_invoice_netto: `Netto`,
preview_invoice_reverse_charge: `Reverse Charge`,
preview_invoice_title: `Preisgestaltung`,
preview_invoice_ust_19: `USt.19%`,
preview_invoice_ust_20: `USt.20%`,
pricing_ctas: (i: P) => `${i as S}x alternativer Call-to-Action` as const,
pricing_hooks: (i: P) => `${i as S}x alternativer Hook` as const,
pricing_nano_service: `Storyboard`,
pricing_photo: `Bild Preis`,
pricing_self_service: `Self Service`,
pricing_video_duration: `Videodauer`,
pricing_video_feature_medium: `Editing: Medium`,
pricing_video_feature_small: `Untertitel`,
pricing_video_type: `Videotyp`,
product_urls_label: `Produkt-URLs (Ausklappbar)`,
profile_settings_analytics_disabled: `Bitte lade die Seite neu, um die Änderungen zu übernehmen.`,
profile_settings_analytics_enable: `Analytics aktivieren`,
profile_settings_analytics_unable: `Analytics deaktivieren`,
profile_settings_change_password: `Passwort ändern`,
profile_settings_email: `E-Mail`,
profile_settings_firstname: `Vorname`,
profile_settings_password: `Passwort`,
profile_settings_save: `Änderungen speichern`,
profile_settings_save_error: `Ups! Beim Speichern ist ein Fehler aufgetreten.`,
profile_settings_surname: `Nachname`,
profile_settings_title: `Profil verwalten`,
recommended: `empfohlen`,
save: `Speichern`,
saved: `Gespeichert`,
scene: `Szene`,
select: `Hinzufügen`,
set_apointment_link: `https://meetings-eu1.hubspot.com/tien-gruenewald/stylink-ugc-de`,
shipping_body: `Bitte wähle deinen Versandpartner aus und gib die Sendungsnummer an.`,
shipping_body_title: `Bitte gib deinen Tracking-Link ein`,
shipping_button: `Sendungsnummer eintragen`,
shipping_button_title: `Tracking-Link eintragen`,
shipping_dialog_title: `Tracking-Link`,
shipping_other_service: `Versandpartner`,
shipping_sent: `Versendet`,
shipping_service: `Versandpartner`,
shipping_title: `Sendungsnummer`,
shipping_tracking_code_label: `Sendungsnummer`,
shipping_tracking_link_label: `Tracking-Link`,
sidebar_headline_brand_settings: `Brand verwalten`,
sidebar_headline_create_campaign: `Auftrag erstellen`,
sidebar_headline_overview: `Auftragsübersicht`,
sidebar_headline_profile_settings: `Profil verwalten`,
sidebar_item_brand_settings: `Brand verwalten`,
sidebar_item_campaigns: `Aufträge`,
sidebarItemContact: `Support`,
sidebar_item_create_brand: `Neue Brand erstellen`,
sidebar_item_feedback: `Feedback`,
sidebar_popup_coupon: `Guthaben aufladen und <b>Bonus</b> sichern!`,
sidebar_popup_coupon_button: `Aufladen`,
sidebar_popup_expert: `Lass dich von unseren Experten <b>kostenlos</b> beraten.`,
sidebar_popup_expert_button: `Termin vereinbaren`,
sidebar_stats_active_campaign: `Aktiver Auftrag`,
sidebar_stats_active_campaigns: `Aktive Aufträge`,
test: (name: P,n: P) => `${name as S} Hallo. ${n as S}` as const,
tutorial_do_not_show_again: `Nicht mehr anzeigen`,
unboxing: `Unboxing`,
unboxing_creator: `Creator`,
unboxing_creator_description: `Szene 1: Produkt wird ausgepackt, Creator ist dabei zu sehen
Szene 2: Produkt wird angewendet`,
unboxing_description: `Produktvideos für deinen Online-Store, dein Amazon Account oder deine Webseite`,
unboxing_faceless: `Faceless & Produktfokus`,
unboxing_faceless_description: `Szene 1: Produktverpackung wird in die Kamera gehalten, es sind lediglich Hände zu sehen
Szene 2: Produkt wird angewendet`,
unboxing_five_videos: `5 Videos`,
unboxing_five_videos_desc: `Lasse 5 Unboxing-Videos produzieren`,
unboxing_ten_videos: `10 Videos`,
unboxing_ten_videos_desc: `Lasse 10 Unboxing-Videos produzieren und erhalte 7% Rabatt`,
unboxing_twenty_videos: `20 Videos`,
unboxing_twenty_videos_desc: `Lasse 20 Unboxing-Videos produzieren und erhalte 13% Rabatt`,
unboxing_video: `Unboxing video`,
understood: `Verstanden!`,
upgrade_video_tutorial_body: `Werte jetzt deine Videos mit Musik, Untertiteln oder Animationen auf, um eine noch bessere Performance zu erzielen!`,
upgrade_video_tutorial_button: `Upgrade wählen`,
upgrade_video_tutorial_joyride_content: `Klicke hier für Upgrades`,
upgrade_video_tutorial_title: `Jetzt deine Videos upgraden.`,
user_role_accountant: `BUCHHALTUNG`,
user_role_admin: `ADMIN`,
user_role_analyst: `ANALYST`,
user_role_marketer: `MARKETING`,
video_feature_medium_description: `✓ Untertitel
+ Logo
+ Call-to-Action`,
video_feature_small_description: `✓ Untertitel`,
wait: `Warten`,
yes: `Ja`,
texterify_timestamp: `2025-03-06T13:17:22Z`
} as const