import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, Menu, MenuItem, Popover, Stack, Typography, useMediaQuery } from '@mui/material'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import { Link, useHistory, useLocation } from 'react-router-dom'
import leftArrow from '../../assets/icons/ic_arrow_left.svg'
import CampaignIconSelected from '../../assets/icons/ic_campaign_selected.svg'
import CampaignIcon from '../../assets/icons/ic_campaign_unselected.svg'
import ArrowIcon from '../../assets/icons/ic_dropdown_black.svg'
import SettingsIconSelected from '../../assets/icons/ic_settings_selected.svg'
import SettingsIcon from '../../assets/icons/ic_settings_unselected.svg'
import expertPig from '../../assets/illustrations/im_expert_pig.svg'
import expert from '../../assets/illustrations/im_new_expert.png'
import { StringsContext, useStrings } from '../../assets/localization/strings'
import { fileToBase64, State, uploadToS3, base64StringtoFile } from '../../reusableUtils/Helpers'
import rootStore from '../../stores/rootStore'
import { color, theme } from '../../theme'
import FeedbackDialog from './FeedbackDialog'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import ReviewsIcon from '@mui/icons-material/Reviews'
import CreditModal from './CreditModal'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, { shouldForwardProp: prop => prop !== 'open' })<AppBarProps & { moveToRight?: boolean }>(({ theme, moveToRight }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(moveToRight && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Main = styled('main')<{ removepadding?: boolean }>(({ theme, removepadding }) => ({
  flexGrow: 1,
  padding: removepadding ? 0 : theme.spacing(3),
  backgroundColor: '#FBFBFB',
  height: '100vh',
  position: 'relative'
}))

const useStyles = makeStyles({
  leftOverlay: {
    backgroundColor: 'white',
    position: 'absolute',
    height: '65px',
    width: '90px',
    marginRight: '40px',
    left: '-17px'
  },
  sideBarContainer: {
    backgroundColor: 'white'
  },
  sidebarItem: {},
  notificationDotSidebar: {
    fontSize: '12px',
    padding: '2px 7px',
    color: 'black',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    borderRadius: '5px',
    position: 'absolute',
    right: '10px',
    background: color.stylinkPurple
  },
  creditBtn: {
    fontSize: '14px',
    minWidth: '170px',
    maxHeight: '40px',
    backgroundColor: '#a890fe',
    padding: '8px 16px 8px 16px'
  },
  creditBoxText: {
    margin: '16px 0px 16px 0px !important',
    whiteSpace: 'normal',
    fontSize: 'small'
  },
  reChargebg: {
    position: 'absolute',
    bottom: '2em !important',
    left: '28px',
    background: color.backgroundPurple,
    borderRadius: '5px',
    width: '191px',
    height: '210px'
  },
  creditIcon: {
    position: 'absolute',
    zIndex: '100',
    bottom: '210px',
    left: '85px',
    width: '80px'
  },
  closeIcon: {
    position: 'absolute',
    zIndex: '100',
    bottom: '190px',
    left: '175px'
  },
  creditContainer: {
    paddingTop: '4em',
    height: '80%'
  },
  sidebarLogo: {
    zIndex: '1000',
    position: 'absolute',
    margin: 'auto',
    top: '60px',
    bottom: 0,
    left: '-50px',
    width: '96px',
    height: '96px',
    borderRadius: '100%'
  },
  changeBrandText: {
    fontSize: '15px',
    lineHeight: '17px',
    cursor: 'pointer',
    paddingTop: '120px'
  },
  activeCampaign: {
    paddingTop: '4px',
    fontSize: '12px',
    color: '#d3d3d3'
  },
  buttonContainer: {
    padding: '10px',
    width: '90% !important',
    height: '45px'
  },
  buttonContainerSelected: {
    backgroundColor: color.backgroundPurple,
    borderRadius: '10px',
    padding: '10px',
    width: '90% !important',
    height: '45px',
    color: '#a890fe'
  },
  headLineContainer: {
    fontSize: '20px'
  },
  headline: {
    color: color.white,
    fontFamily: 'Bricolage Grotesque'
  },
  icon: {
    width: '20px',
    paddingTop: '3px'
  },
  smallIcon: {
    marginTop: '30px !important'
  }
})

enum Selected {
  unselected,
  campaign,
  settings
}

const TextBrandLogo = () => {
  const classes = useStyles()
  const [inputFile, cropperRef] = [useRef<HTMLInputElement>(null), useRef<HTMLImageElement>(null)]
  const [logo, setLogo] = useState<string>()
  const [uploadingLogoProgress, setUploadingLogoProgress] = useState<number>()
  const selectedBrand = rootStore.brandStore.selectedBrand
  if (!selectedBrand) return null
  const onDrop = async (droppedFile: File[]) => {
    const base64 = await fileToBase64(droppedFile[0])
    setLogo(base64 as string)
  }
  const onCrop = async () => {
    setUploadingLogoProgress(0)
    try {
      const imageElement: any = cropperRef?.current
      const cropper: any = imageElement?.cropper
      const file = base64StringtoFile(cropper.getCroppedCanvas().toDataURL(), `logo_${Date.now()}${Math.round(Math.random() * 1000)}`)
      await rootStore.brandStore.updateBrand({
        ...selectedBrand,
        brandLogo: await uploadToS3(file, setUploadingLogoProgress)
      })
    } catch (e) {
      console.error(e)
    }
    setUploadingLogoProgress(undefined)
  }
  return (
    <>
      <Dialog open={!!logo} onClose={() => setLogo(undefined)}>
        <DialogTitle>Brand Logo</DialogTitle>
        <DialogContent>
          <Cropper style={{ width: '400px', height: '300px' }} aspectRatio={1} guides src={logo} ref={cropperRef} />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={onCrop}>
            Zuschneiden
          </Button>
        </DialogActions>
      </Dialog>
      {<input type='file' style={{ display: 'none' }} ref={inputFile} onChange={e => onDrop(e.target.files ? Array.from(e.target.files) : [])} />}
      <Box display='inline' className={classes.sidebarLogo} justifyContent='center' justifyItems='center' onClick={() => inputFile.current?.click()}>
        <Typography fontSize={70}>{selectedBrand.brandName?.slice(0, 2)}</Typography>
        {uploadingLogoProgress != null && <LinearProgress variant='determinate' value={uploadingLogoProgress * 100} />}
      </Box>
    </>
  )
}

const DealView = ({ showDeal: show, setShowDeal: setShow }: State<'showDeal', boolean>) => {
  const deal = rootStore.brandStore.selectedBrand?.deal
  const dealWasShownKey = 'dealWasShownKey'
  const getMap = () => JSON.parse(window.localStorage.getItem(dealWasShownKey) || '{}') as Record<string, boolean>

  useEffect(() => {
    setShow(!!deal && !getMap()[deal.id])
  }, [])
  if (!deal || !show) return null

  const close = () => {
    setShow(false)
    const wasShown = getMap()
    wasShown[deal.id] = true
    window.localStorage.setItem(dealWasShownKey, JSON.stringify(wasShown))
  }

  return (
    <Box position='relative'>
      <Stack alignItems='center' height='100%' justifyContent='center' position='absolute' right={0} mr={1}>
        <IconButton onClick={close}>
          <CloseIcon sx={{ mixBlendMode: 'difference', color: 'white' }} fontSize='small' />
        </IconButton>
      </Stack>
      <div dangerouslySetInnerHTML={{ __html: deal.text }} />
    </Box>
  )
}

const SelectCountry = () => {
  const { language, setLanguage } = useContext(StringsContext)
  const codeToCountry = { de: '🇩🇪', en: '🇬🇧' }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Box>
      <Grid onClick={e => setAnchorEl(e.currentTarget)} height={40} width={30} container alignItems='center' justifyContent='center'>
        {codeToCountry[language]}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        {Object.entries(codeToCountry)
          .ascendingSorted(p => (p[0] == language ? 0 : 1))
          .map(([code, emoji]) => (
            <MenuItem
              key={code}
              sx={{ fontSize: 20 }}
              onClick={() => {
                setLanguage(code)
                setAnchorEl(null)
              }}>
              {emoji}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  )
}

const SidebarDrawer = observer(({ children, showSidebar, removepadding }: { children: React.ReactNode; showSidebar?: boolean; removepadding?: boolean }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showExpert, setShowExpert] = useState(false)
  const [hasPayment, setHasPayment] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [showBottomCard, setShowBottomCard] = useState<boolean>(true)
  const [showDeal, setShowDeal] = useState(false)
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)

  const anchorRef = useRef<HTMLSpanElement | null>(null)
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const strings = useStrings()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!rootStore.userStore.authenticatedUser) {
      const onMount = async () => {
        try {
          await rootStore.userStore.getUser()
        } catch (e) {
          console.error(e)
        }
      }
      onMount()
    }
    if (rootStore.brandStore.selectedBrand) {
      setHasPayment(rootStore.brandStore.selectedBrand.hasPaymentMethod !== undefined ? rootStore.brandStore.selectedBrand.hasPaymentMethod : false)
    }
    if (rootStore.campaignStore.campaigns) {
      if (rootStore.campaignStore.campaigns.length > 0) {
        setShowExpert(false)
      }
    }
    if (!hasPayment) {
      setShowExpert(true)
    }
    setShowBottomCard(!bottomCardClosed())
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleSwitchBrandOpen = () => {
    setAnchorEl(anchorRef.current)
  }

  const handleSwitchBrandClose = () => {
    setAnchorEl(null)
  }

  const bottomCardClosed = () => localStorage.getItem('sidebarcard') === 'closed'

  const toggleBottomCard = () => {
    if (!bottomCardClosed()) {
      setShowBottomCard(false)
      localStorage.setItem('sidebarcard', 'closed')
    } else {
      setShowBottomCard(true)
      localStorage.setItem('sidebarcard', 'open')
    }
  }

  let headLine = ''

  let selected = Selected.unselected

  if (pathname.endsWith('dashboard')) {
    headLine = strings.sidebar_headline_overview
    selected = Selected.campaign
  } else if (pathname.endsWith('createCampaign') || pathname.endsWith('createContentCampaign')) {
    headLine = strings.sidebar_headline_create_campaign
    selected = Selected.unselected
  } else if (
    pathname.endsWith('overview') ||
    pathname.endsWith('participants') ||
    pathname.endsWith('applicants') ||
    pathname.endsWith('statistics') ||
    pathname.endsWith('tiktok') ||
    pathname.endsWith('fromOverview') ||
    pathname.includes('content')
  ) {
    headLine = rootStore.campaignStore.selectedCampaign?.title || ''
    selected = Selected.campaign
  } else if (pathname.includes('settings') && !pathname.endsWith('settings')) {
    headLine = strings.sidebar_headline_brand_settings
    selected = Selected.settings
  } else if (pathname.endsWith('settings')) {
    headLine = strings.sidebar_headline_profile_settings
    selected = Selected.unselected
  } else {
    selected = Selected.unselected
  }
  const showCreditButton = () => {
    if (!showBottomCard)
      return (
        <IconButton sx={{ position: 'absolute', bottom: '22px', left: '22px', background: color.stylinkPurple }} onClick={() => toggleBottomCard()}>
          <QuestionMarkIcon style={{ color: color.white }} />
        </IconButton>
      )

    return (
      <Grid container direction='column' className={classes.creditContainer}>
        <img src={showExpert ? expert : expertPig} alt={showExpert ? 'expert' : 'creditArrow'} className={classes.creditIcon} />
        <IconButton className={classes.closeIcon} onClick={() => toggleBottomCard()}>
          <CloseIcon fontSize='small' />
        </IconButton>
        <Grid item xs={9}>
          <div className={classes.reChargebg}>
            <Grid container justifyContent='center' style={{ paddingTop: '44px' }}>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid item xs={1} style={{ paddingLeft: '8px' }}>
                    {!showExpert && (
                      <div onClick={() => setShowExpert(true)}>
                        <img src={leftArrow} />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={10}>
                    <div style={{ textAlign: 'center', height: '70px' }}>
                      {showExpert ? (
                        <p className={classes.creditBoxText}>
                          <div dangerouslySetInnerHTML={{ __html: strings.sidebar_popup_expert }} />
                        </p>
                      ) : (
                        <p className={classes.creditBoxText}>
                          <div dangerouslySetInnerHTML={{ __html: strings.sidebar_popup_coupon }} />
                        </p>
                      )}
                    </div>
                  </Grid>
                  {/* <Grid item xs={1}>
                  {showExpert && (
                    <div onClick={() => setShowExpert(false)}>
                      <img src={rightArrow} />
                    </div>
                  )}
                </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container justifyContent='center'>
                  <button
                    className={classes.creditBtn}
                    onClick={
                      showExpert ? () => window.open(strings.set_apointment_link, '_blank') : handleOpen
                    }>
                    {showExpert ? strings.sidebar_popup_expert_button : strings.sidebar_popup_coupon_button}
                  </button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Grid container justifyContent='center'>
              {hasPayment && (
                <img src={showExpert ? selectLeft : selectRight} />
              )}
            </Grid>
          </Grid> */}
          </div>
        </Grid>
      </Grid>
    )
  }

  const bigContent = () => {
    const activeAndVerified = rootStore.campaignStore.campaigns?.filter(el => el.isVerified && el.isActive).length
    return (
      <Box width={drawerWidth}>
        {isMobile && (
          <>
            <IconButton onClick={handleDrawerClose} sx={{ width: '100%' }}>
              <ChevronLeftIcon sx={{ ml: 'auto' }} />
            </IconButton>
            <Divider />
          </>
        )}
        <Grid className={classes.sideBarContainer}>
          <Grid className='campaignSidebarContainer'>
            <div className='campaignSidebar'>
              <div className='imageContainer'>
                {rootStore.brandStore.selectedBrand?.brandLogo ? (
                  <img className={classes.sidebarLogo} src={rootStore.brandStore.selectedBrand.brandLogo} alt='Logo' />
                ) : (
                  <TextBrandLogo />
                )}
              </div>
              <div className={classes.changeBrandText}>
                <span onClick={handleSwitchBrandOpen} ref={anchorRef}>
                  <b>{rootStore.brandStore.selectedBrand?.brandName}</b>
                  <img src={ArrowIcon} alt='>' />
                </span>
                <Popover
                  open={!!anchorEl}
                  onClose={handleSwitchBrandClose}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  PaperProps={{ style: { width: 'fitContent' } }}>
                  <Grid container maxWidth='200px'>
                    {rootStore.brandStore.brands?.map(
                      brand =>
                        brand.brandId && (
                          <Grid item xs={12} key={brand.brandId}>
                            <Button
                              fullWidth
                              sx={{ color: color.black, borderRadius: '0px' }}
                              onClick={async () => {
                                await rootStore.brandStore.setSelectedBrand(brand.brandId)
                                history.push(`/${brand.brandId}/dashboard`)
                              }}>
                              {brand.brandName}
                            </Button>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Popover>
              </div>
              <div className={classes.activeCampaign}>
                {activeAndVerified}
                {activeAndVerified == null ? (
                  <span style={{ visibility: 'hidden' }}>unknown</span>
                ) : activeAndVerified == 1 ? (
                  ` ${strings.sidebar_stats_active_campaign}`
                ) : (
                  ` ${strings.sidebar_stats_active_campaigns}`
                )}
              </div>
              <Grid container style={{ marginTop: '3em' }}>
                <Grid item xs={12} className={classes.sidebarItem} style={{ paddingLeft: '1em', marginBottom: '1em' }}>
                  <Link to={`/${rootStore.brandStore.selectedBrand?.brandId}/dashboard`}>
                    <Grid container className={selected === Selected.campaign ? classes.buttonContainerSelected : classes.buttonContainer}>
                      <Grid container alignItems='center'>
                        <Grid item xs={3}>
                          <img id='campaignIcon' src={selected === Selected.campaign ? CampaignIconSelected : CampaignIcon} alt='campaignIcon' className={classes.icon} />
                        </Grid>
                        <Grid item xs={9}>
                          {strings.sidebar_item_campaigns}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: '1em', marginBottom: '1em' }}>
                  <Link to={`/${rootStore.brandStore.selectedBrand?.brandId}/settings/brand`}>
                    <Grid container className={selected === Selected.settings ? classes.buttonContainerSelected : classes.buttonContainer} alignItems='center'>
                      <Grid item xs={3}>
                        <img id='brandIcon' src={selected === Selected.settings ? SettingsIconSelected : SettingsIcon} alt='brandicon' className={classes.icon} />
                      </Grid>
                      <Grid item xs={9}>
                        {strings.sidebar_item_brand_settings}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: '1em', marginBottom: '1em' }}>
                  <Link to='/createBrand'>
                    <Grid container className={classes.buttonContainer} alignItems='center'>
                      <Grid item xs={3}>
                        <AddCircleIcon sx={{ color: '#4f4f4f' }} />
                      </Grid>
                      <Grid item xs={9}>
                        {strings.sidebar_item_create_brand}
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: '1em', marginBottom: '1em', cursor: 'pointer' }} onClick={() => setShowFeedbackDialog(true)}>
                  <Grid container className={classes.buttonContainer} alignItems='center'>
                    <Grid item xs={3}>
                      <ReviewsIcon sx={{ color: '#4f4f4f' }} />
                    </Grid>
                    <Grid item xs={9}>
                      {strings.sidebar_item_feedback}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {rootStore.brandStore.selectedBrand && <Grid item>{showCreditButton()}</Grid>}
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const renderToolbar = (hidden: boolean) => (
    <Stack width='100%' sx={{ visibility: hidden ? 'hidden' : 'visible' }}>
      <Stack width='100%' direction='row' sx={{ background: color.stylinkPurple, color: 'black', p: 1, pr: 2, pl: 2 }}>
        {isMobile && showSidebar && (
          <div>
            <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerOpen} edge='start'>
              <MenuIcon />
            </IconButton>
          </div>
        )}
        <Grid container alignItems='center' justifyContent='space-between' className={classes.headLineContainer}>
          <Grid item xs={6}>
            <b className={classes.headline}>{headLine}</b>
          </Grid>
          <Grid container item xs={6} alignItems='center' justifyContent='flex-end'>
            {rootStore.brandStore.selectedBrand && (
              <Grid item p={isMobile ? 0 : 1}>
                <a
                  href={`mailto:ugc-brands@stylink.com?subject=${encodeURIComponent('UGC Problem') || ''}&body=${
                    encodeURIComponent('Brand: ' + rootStore.brandStore.selectedBrand?.brandName) || ''
                  }`}>
                  {isMobile ? (
                    <IconButton sx={{ p: isMobile ? 0.5 : 1, color: color.white }}>
                      <ContactSupportIcon />
                    </IconButton>
                  ) : (
                    <Button variant='outlined' color='secondary'>
                      <ContactSupportIcon sx={{ mr: 1 }} /> {strings.sidebarItemContact}
                    </Button>
                  )}
                </a>
              </Grid>
            )}
            <Grid item>
              <SelectCountry />
            </Grid>
            <Grid item>
              <IconButton onClick={() => history.push('/settings')} sx={{ p: isMobile ? 0.5 : 1, color: color.white }}>
                <AccountCircleIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => rootStore.authStore.logout().then(() => history.push('/login'))} sx={{ p: isMobile ? 0.5 : 1, color: color.white }}>
                <LogoutIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <DealView showDeal={showDeal} setShowDeal={setShowDeal} />
    </Stack>
  )
  return (
    <>
      <FeedbackDialog open={showFeedbackDialog} onClose={() => setShowFeedbackDialog(false)} />
      <CreditModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar sx={{ boxShadow: 'none !important', backgroundColor: 'transparent', p: 0 }} moveToRight={isMobile ? false : showSidebar}>
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0, minHeight: 0 }}>{renderToolbar(false)}</Toolbar>
        </AppBar>
        {isMobile ? (
          <Drawer open={open} onClose={() => setOpen(false)}>
            {bigContent()}
          </Drawer>
        ) : (
          showSidebar && bigContent()
        )}
        <Main id='main' removepadding={removepadding} sx={{ overflow: 'scroll' }}>
          {renderToolbar(true)}
          {children}
        </Main>
      </Box>
    </>
  )
})

export default SidebarDrawer
