import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useStrings } from '../../../assets/localization/strings'
import { ShippingService } from '../../../models/GeneralTypes'
import { ParticipantUgc } from '../../../requestModels'

type Props = {
  open: boolean
  onClose: () => void
  handleSent: (_: ParticipantUgc, shippingCode: string, shippingService?: ShippingService | string) => Promise<void>
  influencer: ParticipantUgc | undefined
  loading: boolean
}

export const ShippingDialog = ({ open, onClose, handleSent, influencer, loading }: Props) => {
  const [shippingCode, setShippingCode] = useState('')
  const [isValidLink, setIsValidLink] = useState(true)
  const strings = useStrings()

  const validateLink = (link: string) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
    return !!urlPattern.test(link) && link.includes('.')
  }

  const onSent = async () => {
    if (influencer) {
      await handleSent(influencer, shippingCode)
      setShippingCode('')
    } else {
      alert(strings.error)
    }
  }

  const handleShippingCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setShippingCode(value)
    setIsValidLink(validateLink(value))
  }

  const isDisabled = !shippingCode || loading || !isValidLink

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <div>
        <DialogTitle>{strings.shipping_dialog_title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }} sx={{ fontSize: 16 }}>{strings.shipping_body_title}</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='shippingCode'
            label={strings.shipping_tracking_link_label}
            color='primary'
            value={shippingCode}
            onChange={handleShippingCodeChange}
            type='text'
            fullWidth
            variant='standard'
            error={!isValidLink}
            helperText={!isValidLink ? strings.invalid_link : ''}
          />
        </DialogContent>
        <DialogActions>
          <button onClick={onSent} disabled={isDisabled}>
            {strings.shipping_sent}
          </button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ShippingDialog
